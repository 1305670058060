import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

const Footer = props => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allFooterMenuJson {
        edges {
          node {
            weight
            url
            name
          }
        }
      }
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  return (
    <div className="footer">
      <div className="container">
        <div className="footer-inner">
          {/* Company Info */}
          <div className="footer-widget">
            <h3 className="footer-title">{data.site.siteMetadata.title}</h3>
            <p className="footer-description">
              Redefining financial success through cutting-edge AI/ML technologies and advanced investment strategies.
            </p>
           
          </div>

          {/* Services */}
          <div className="footer-widget">
            <h3 className="footer-title">Our Services</h3>
            <div className="footer-menu">
              <p>→ AI-Driven Trading</p>
              <p>→ Financial Advisory</p>
              <p>→ Asset Management</p>
              <p>→ Risk Analysis</p>
            </div>
          </div>

          {/* Contact Info */}
          <div className="footer-widget">
            <div className="footer-contact">
              <h3 className="footer-title">Get in Touch</h3>
              <div className="contact-info">
                <p>
                  <i className="fas fa-envelope"></i>
                  contact@infinitymachine.capital
                </p>
                <p>
                  <i className="fas fa-map-marker-alt"></i>
                  71 Ayer Rajah Crescent, #02-10/11, Singapore (139951)
                </p>
              </div>
              <Link to="/contact" className="button button-contact">
                CONTACT US
              </Link>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <p>© {new Date().getFullYear()} {data.site.siteMetadata.title}. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
